<template>
  <b-row>
    <b-col md="4">
      <input-text
        ref="numeroNotaFiscal"
        v-model="filtro.numeroNotaFiscal"
        :label="$t('SUPRIMENTOS_LANCAMENTOS_NF.NUMERO_NOTA_FISCAL')"
        @enter="filtrar"
      />
    </b-col>

    <!-- data de emissão -->
    <b-col md="2">
      <input-date-only-btn
        ref="dataEmissaoInicio"
        v-model="filtro.dataEmissaoInicio"
        label="SUPRIMENTOS_LANCAMENTOS_NF.DATA_EMISSAO_INICIO"
      />
    </b-col>
    <b-col md="2">
      <input-date-only-btn
        ref="dataEmissaoAte"
        v-model="filtro.dataEmissaoAte"
        label="SUPRIMENTOS_LANCAMENTOS_NF.DATA_EMISSAO_ATE"
      />
    </b-col>

    <!-- data de vencimento -->
    <b-col md="2">
      <input-date-only-btn
        ref="dataVencimentoInicio"
        v-model="filtro.dataVencimentoInicio"
        label="SUPRIMENTOS_LANCAMENTOS_NF.DATA_VENCIMENTO_INICIO"
      />
    </b-col>
    <b-col md="2">
      <input-date-only-btn
        ref="dataVencimentoAte"
        v-model="filtro.dataVencimentoAte"
        label="SUPRIMENTOS_LANCAMENTOS_NF.DATA_VENCIMENTO_ATE"
      />
    </b-col>
    <b-col md="auto">
      <b-button variant="primary" class="mb-3 w-100" @click="filtrar">
        {{ $t('GERAL.FILTRAR') }}
      </b-button>
    </b-col>
    <b-col md="auto">
      <b-button variant="secondary" class="mb-3 w-100" @click="limparFiltro">
        {{ $t('GERAL.LIMPAR') }}
      </b-button>
    </b-col>
  </b-row>
</template>
<script>
import helpers from '@/common/utils/helpers';

// Components:
import { InputDateOnlyBtn, InputText } from '@/components/inputs';

export default {
  components: {
    // Filtro,
    InputDateOnlyBtn,
    InputText,
  },
  data() {
    return {
      filtro: {},
    };
  },
  mounted() {
    this.$emit('filtrar', this.filtro);
  },
  methods: {
    filtrar() {
      if (!this.validarFormulario()) return;
      this.$emit('filtrar', this.filtro);
    },
    limparFiltro() {
      this.filtro = {};
      this.$emit('limparFiltro');
    },
    validarFormulario() {
      return helpers.validarFormulario(this.$refs);
    },
  },
};
</script>
