<template>
  <div>
    <titulo-lancamentos-nf />
    <filtro-lancamentos-nf @filtrar="getNotasFiltradas" />
    <tabela-lancamentos-nf :items="items" @atualizar="getNotasFiltradas" />
    <paginacao
      v-model="paginacao"
      :total="paginacao.total"
      @input="getNotasFiltradas"
    />
  </div>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import breadcrumb from '@/common/utils/breadcrumb';
import mensagem from '@/common/utils/mensagem';
import rotas from '@/common/utils/rotas';


// Services:
import NotaFiscalService from '@/common/services/nota-fiscal/nota-fiscal.service.js';

// Components:
import TituloLancamentosNf from '@/views/suprimentos/lancamentos-nf/components/TituloLancamentosNF';
import TabelaLancamentosNf from './components/Tabela';
import FiltroLancamentosNf from './components/filtro/Index';
import Paginacao from '@/components/paginacao/Paginacao';

export default {
  components: {
    TituloLancamentosNf,
    FiltroLancamentosNf,
    TabelaLancamentosNf,
    Paginacao,
  },
  mounted() {
    breadcrumb.definir(this.$store, [
      { titulo: this.$t('SUPRIMENTOS.SUPRIMENTOS') },
      { titulo: this.$t('SUPRIMENTOS.LANCAMENTOS_NF') },
    ]);
  },
  data() {
    return {
      filtrandoPor: {},
      items: [],
      paginacao: {
        numeroDaPagina: 1,
        tamanhoDaPagina: 10,
        total: 0,
      },
    };
  },
  methods: {
    cadastrar() {
      return rotas.irPara('criar-nf');
    },
    getNotasFiltradas(filtro) {
      filtro ? (this.filtrandoPor = filtro) : (filtro = this.filtrandoPor);
      this.$store.dispatch(START_LOADING);
      NotaFiscalService.obterPorFiltro(this.filtrandoPor, this.paginacao)
        .then(({ data }) => {
          this.items = data.itens;
          this.paginacao.total = data.paginacao.totalDeElementos;
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
  },
};
</script>
