<template>
  <b-table
    :fields="fields"
    :items="items"
    :empty-text="$t('GERAL.NENHUM_REGISTRO')"
    head-variant="light"
    stacked="md"
    responsive
    show-empty
    striped
    hover
  >
    <template #cell(acoes)="item">
      <b-dropdown variant="outline-default">
        <b-dropdown-item @click="visualizar(item.item.id)">
          {{ $t('GERAL.VISUALIZAR') }}
        </b-dropdown-item>
        <b-dropdown-item @click="confirmarExclusao(item.item)">
          {{ $t('GERAL.EXCLUIR') }}
        </b-dropdown-item>
      </b-dropdown>
    </template>
  </b-table>
</template>
<script>
// Utils & Aux:
import { START_LOADING, STOP_LOADING } from '@/store/Store';
import mensagem from '@/common/utils/mensagem';
import helpers from '@/common/utils/helpers';
import rotas from '@/common/utils/rotas';

// Services:
import NotaFiscalService from '@/common/services/nota-fiscal/nota-fiscal.service.js';

export default {
  props: {
    items: { type: Array, default: Array },
  },
  data() {
    return {
      fields: [
        {
          key: 'numeroNotaFiscal',
          label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.NUMERO_NOTA_FISCAL'),
          sortable: true,
        },
        {
          key: 'fornecedor',
          label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.FORNECEDOR'),
          sortable: true,
        },
        {
          key: 'dataEmissao',
          label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.DATA_EMISSAO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'dataVencimento',
          label: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.DATA_VENCIMENTO'),
          formatter: (value) => {
            return value ? helpers.formatarDataBr(value) : '-';
          },
          sortable: true,
        },
        {
          key: 'acoes',
          label: this.$t('GERAL.ACOES'),
        },
      ],
    };
  },
  methods: {
    visualizar(itemId) {
      rotas.irPara('suprimentos-lancamento-nf', itemId);
    },
    async confirmarExclusao(item) {
      const respostaExclusao = await mensagem.confirmacao(
        this.$t('GERAL.CONFIRMAR_EXCLUSAO'),
        this.$t('GERAL.CONFIRMAR_EXCLUSAO_ITEM').formatUnicorn({
          item: `nota fiscal nº ${item.numeroNotaFiscal}`,
        })
      );
      respostaExclusao.isConfirmed ? this.excluir(item.id) : {};
    },
    excluir(notaFiscalId) {
      this.$store.dispatch(START_LOADING);
      NotaFiscalService.excluir(notaFiscalId)
        .then(() => {
          this.$emit('atualizar');
          mensagem.sucesso(
            this.$t('GERAL.SUCESSO'),
            this.$t('GERAL.ITEM_DELETADO').formatUnicorn({
              item: this.$t('SUPRIMENTOS_LANCAMENTOS_NF.NOTA_FISCAL'),
            })
          );
        })
        .catch((err) => {
          mensagem.showErrors(err);
        })
        .finally(() => {
          this.$store.dispatch(STOP_LOADING);
        });
    },
    irPara(rota, id) {
      rotas.irPara(rota, id);
    },
  },
};
</script>
